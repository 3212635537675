import React from "react";

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Collapse,
    Typography,
    Grid,
    FormControlLabel,
    Divider,
    makeStyles,
    Theme,
    CircularProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListItemIcon
} from "@material-ui/core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { emptyRouteParameters, AppRoutesPath, to } from "../../utils/routes";
import { Position } from "../../components/ui/Map";
import { DatabaseComment } from "../../components/ui/Dialogs/DatabaseComment";
import { Status, StatusIcon } from "../../components/ui/StatusLight";
import { ProductEvent } from "./Dashboard";

export interface Mr2Internal {
    id: string;
    letter: string;
    nameDatabase: string;
    active: boolean;
    comment: string;
    nameSite: string;
    position: Position;
    displayName: string;
    status: { radar: Status; radarText: string; data: Status; dataText: string };
    events: ProductEvent[];
}

const statusItemWidth = 20;
const buttonItemWidth = 110;
const eventsItemWidth = 600;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        minHeight: 460,
        alignItems: "center"
    },
    listHeaderStatus: {
        width: statusItemWidth,
        justifyContent: "right"
    },
    listHeaderButton: {
        width: 2 * buttonItemWidth + 20
    },
    listActionsGridItemButton: {
        display: "flex",
        alignItems: "center",
        width: buttonItemWidth,
        justifyContent: "flex-end"
    },
    listActionsGridItemStatus: {
        display: "flex",
        alignItems: "right",
        width: statusItemWidth,
        justifyContent: "left",
        marginTop: 10
    },
    listActionsGridItemEvents: {
        display: "flex",
        alignItems: "center",
        width: eventsItemWidth,
        justifyContent: "left"
    },
    listActionsGridItemEventClearButton: {
        display: "fixed",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}));

interface Mr2DashboardProps {
    mr2s: Mr2Internal[];
    loaded: boolean;
    error: string;
    showOnlyActiveProducts: boolean;
    onHandleEvent: (nameDatabase: string, event: ProductEvent) => void;
    onSetDatabaseActiveClicked: (databaseName: string, active: boolean) => void;
}

export const Mr2Dashboard = (props: Mr2DashboardProps): React.ReactElement => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { loaded, error, mr2s } = props;

    const [openMr2Entries, setOpenMr2Entries] = React.useState(true);

    const handleopenMr2EntriesClick = (): void => {
        setOpenMr2Entries(!openMr2Entries);
    };

    if (loaded === false) {
        return (
            <Box className={classes.loadingContainer}>
                <CircularProgress />
            </Box>
        );
    }
    if (error !== "") {
        return (
            <Typography variant="body2" color="error">
                {error}
            </Typography>
        );
    }
    if (mr2s.length === 0) {
        return <Typography variant="body2"> {t("home.noRadarFound")}</Typography>;
    }

    mr2s.sort((radarA: Mr2Internal, radarB: Mr2Internal): number => {
        let radarAEvents = 0;
        if (radarA.events) {
            radarA.events.forEach((event) => {
                if (event.active) {
                    radarAEvents++;
                }
            });
        }
        if (radarA.status.radar !== Status.Running) {
            radarAEvents += 1000;
        }
        if (radarA.status.data !== Status.Running) {
            radarAEvents += 100;
        }
        let radarBEvents = 0;
        if (radarB.events) {
            radarB.events.forEach((event) => {
                if (event.active) {
                    radarBEvents++;
                }
            });
        }
        if (radarB.status.radar !== Status.Running) {
            radarBEvents += 1000;
        }
        if (radarB.status.data !== Status.Running) {
            radarBEvents += 100;
        }
        if (radarAEvents < radarBEvents) {
            return 1;
        } else if (radarAEvents > radarBEvents) {
            return -1;
        }

        return 0;
    });

    const activeEventsMap = new Map();
    const clearingEventsMap = new Map();

    mr2s.forEach((mr2) => {
        if (mr2.events) {
            mr2.events.forEach((event) => {
                if (event.active) {
                    if (event.handledByUser) {
                        if (clearingEventsMap.has(Number(mr2.id))) {
                            clearingEventsMap.get(Number(mr2.id)).push(event);
                        } else {
                            clearingEventsMap.set(Number(mr2.id), new Array<ProductEvent>(event));
                        }
                    } else {
                        if (activeEventsMap.has(Number(mr2.id))) {
                            activeEventsMap.get(Number(mr2.id)).push(event);
                        } else {
                            activeEventsMap.set(Number(mr2.id), new Array<ProductEvent>(event));
                        }
                    }
                }
            });
        }
    });

    return (
        <>
            <Grid container direction="row">
                <Typography variant="overline">{t("home.mr2Title")}</Typography>
                <Grid item className={classes.listHeaderButton}>
                    <Button onClick={handleopenMr2EntriesClick}>
                        {openMr2Entries ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                </Grid>
                <Grid container spacing={10}>
                    <Grid item style={{ flex: 1 }} xs={10} />
                    <Grid item className={classes.listHeaderStatus}>
                        <Typography variant="caption">Radar</Typography>
                    </Grid>
                    <Grid item className={classes.listHeaderStatus}>
                        <Typography variant="caption">Data</Typography>
                    </Grid>
                    <Grid item className={classes.listHeaderButton} />
                </Grid>
            </Grid>
            <Collapse in={openMr2Entries} timeout="auto" unmountOnExit>
                <List>
                    {mr2s.map((mr2, index) => {
                        if (props.showOnlyActiveProducts && !mr2.active) {
                            return <React.Fragment key={index}></React.Fragment>;
                        }

                        return (
                            <>
                                <ListItem alignItems="center" key={index}>
                                    <ListItemAvatar>
                                        <Avatar>{mr2.letter}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={mr2.displayName} />
                                    <ListItemSecondaryAction>
                                        <Grid container spacing={10} direction="row">
                                            <DatabaseComment
                                                nameDatabase={mr2.nameDatabase}
                                                comment={mr2.comment}
                                            />
                                            <Grid item className={classes.listActionsGridItemStatus}>
                                                <StatusIcon
                                                    status={mr2.status.radar}
                                                    tooltipText={mr2.status.radarText}
                                                />
                                            </Grid>
                                            <Grid item className={classes.listActionsGridItemStatus}>
                                                <StatusIcon
                                                    status={mr2.status.data}
                                                    tooltipText={mr2.status.dataText}
                                                />
                                            </Grid>
                                            <Grid item className={classes.listActionsGridItemButton}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    component={Link}
                                                    to={to(AppRoutesPath.mr2Live, { ...emptyRouteParameters, mr2id: mr2.id })}
                                                >
                                                    Detail
                                                </Button>
                                            </Grid>
                                            <Grid item className={classes.listActionsGridItemButton}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={mr2.active}
                                                            onClick={(): void => {
                                                                props.onSetDatabaseActiveClicked(
                                                                    mr2.nameDatabase,
                                                                    !mr2.active
                                                                );
                                                            }}
                                                            color="secondary"
                                                        />
                                                    }
                                                    label={"active"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ListItemSecondaryAction>
                                </ListItem>

                                {activeEventsMap.has(Number(mr2.id)) ? (
                                    <List style={{ marginLeft: 100, marginTop: -20, width: "80%", maxWidth: 600 }}>
                                        {activeEventsMap.get(Number(mr2.id)).map((activeEvent: ProductEvent) => {
                                            return (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <StatusIcon status={Status.Error} tooltipText={""} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography>{activeEvent.eventDescription}</Typography>
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            onClick={(): void => {
                                                                props.onHandleEvent(mr2.nameDatabase, activeEvent);
                                                            }}
                                                        >
                                                            {t("administration.clearEvent")}
                                                        </Button>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    ""
                                )}
                                {clearingEventsMap.has(Number(mr2.id)) ? (
                                    <List style={{ marginLeft: 100, marginTop: -20, width: "80%", maxWidth: 574 }}>
                                        {clearingEventsMap.get(Number(mr2.id)).map((clearingEvent: ProductEvent) => {
                                            return (
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <StatusIcon status={Status.Error} tooltipText={""} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography>{clearingEvent.eventDescription}</Typography>
                                                    </ListItemText>
                                                    <ListItemSecondaryAction>
                                                        <CircularProgress size={12}></CircularProgress>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                ) : (
                                    ""
                                )}
                                {index % 4 === 0 && mr2s.length !== index ? <Divider /> : ""}
                            </>
                        );
                    })}
                </List>
            </Collapse>
        </>
    );
};
