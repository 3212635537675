import React, { useState, useCallback } from "react";

import { Grid } from "@material-ui/core";
import { Mr2 } from "../ProductMr2";
import { useOutletContext } from "react-router";


export const Mr2Live = (): React.ReactElement => {
    const mr2 = useOutletContext<Mr2>();

    const [selectedDate] = useState<string | undefined>(undefined);

    const onSelectedDateChanged = useCallback((newSelectedDate: string) => {
        //setselectedDate(newSelectedDate);
    }, []);

    return (
        <Grid item xs={12} md={10}>
            <Grid container direction="column">
                <Grid item>
                    {
                        /*
                    <MtrChartLive
                        radar={radar}
                        selectedWindmillNumber={selectedWindmillNumber}
                        onChangeSelectedDate={onSelectedDateChanged}
                    />
                    */
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};
