import React, { useState, useEffect, useContext } from "react";
import { Card, CardContent, Grid, Typography, Box, CircularProgress, makeStyles } from "@material-ui/core";

import { Trans } from "react-i18next";
import moment from "moment-timezone";
import { fetchPost } from "../../utils/fetch";
import { AppContext } from "../../store/context";

import { StatusLight, getStatusFromLastContactTime } from "../ui/StatusLight";
import { Mr2 } from "../../views/ProductMr2";

interface StatusData {
    lastContactTimeStamp: string;
}

interface CardMr2Props {
    class: string;
    mr2: Mr2;
}

const useStyles = makeStyles({
    radarName: {
        display: "flex"
    }
});

export const CardMr2 = (props: CardMr2Props): React.ReactElement => {
    const [statusData, setstatusData] = useState<undefined | StatusData>(undefined);
    const classes = useStyles();
    const { state } = useContext(AppContext);

    useEffect(() => {
        const requestStatusData = (): void => {
            if (state.user) {
                fetchPost("get_status_data_mr2.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.mr2.name
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setstatusData(data);
                    });
            }
        };
        requestStatusData();

        const id = setInterval(() => {
            requestStatusData();
        }, 10000);

        return (): void => clearInterval(id);
    }, [state.user, setstatusData, props.mr2]);

    if (statusData === undefined) {
        return (
            <Card className={props.class}>
                <CardContent style={{ textAlign: "center" }}>
                    <CircularProgress></CircularProgress>
                </CardContent>
            </Card>
        );
    }

    const lastContactMoment = moment.tz(statusData.lastContactTimeStamp, "YYYY-MM-DD HH:mm:ss", "UTC");
    const radarStatus = getStatusFromLastContactTime(lastContactMoment);
    return (
        <Card className={props.class}>
            <CardContent>
                <Typography variant="overline">
                    <Trans>sidebar.cardMr2.title</Trans>
                </Typography>

                <Grid justifyContent="space-between" container>
                    <Grid item className={classes.radarName}>
                        <Typography style={{ flex: 1 }} variant="h6">
                            {"MR2 " + props.mr2.siteName}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.radarName}>
                        <StatusLight status={radarStatus}></StatusLight>
                    </Grid>
                </Grid>

                <Box marginBottom={2}>
                    <Typography variant="caption" align="justify">
                        <Trans>sidebar.cardRadar.lastContact</Trans> (UTC):{" "}
                        {lastContactMoment.format("DD.MM.YYYY HH:mm:ss")}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};
