import React from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { MapContainer as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export enum MarkerTypes {
    Ac1,
    Mr1,
    Mr2
}

export interface Position {
    longitude: number;
    latitude: number;
}

interface MapProps {
    markers: { letter: string; position: Position; type: MarkerTypes }[];
}
const useStyles = makeStyles<Theme>({
    root: {
        height: 520,
        overflow: "hidden"
    },
    map: {
        height: 520
    }
});

export const Map = (props: MapProps): React.ReactElement => {
    const classes = useStyles();
    const Mr1Icon = L.icon({
        iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [30, 40],
        iconAnchor: [15, 40]
    });
    const Ac1Icon = L.icon({
        iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",

        iconSize: [30, 40],
        iconAnchor: [15, 40]
    });
    const Mr2Icon = L.icon({
        iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [30, 40],
        iconAnchor: [15, 40]
    });

    const bounds: LatLngTuple[] = props.markers.map((marker) => [marker.position.latitude, marker.position.longitude]);

    const attribution = "&copy; <a href=" + '"http://osm.org/copyright">OpenStreetMap</a> contributors';

    return (
        <Box className={classes.root}>
            <LeafletMap className={classes.map} bounds={bounds}>
                <TileLayer
                    attribution={attribution}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {props.markers.map(
                    (marker, index): React.ReactElement => {
                        let chosenIcon = Mr1Icon;
                        if ( marker.type === MarkerTypes.Ac1 ) { chosenIcon = Ac1Icon };
                        if ( marker.type === MarkerTypes.Mr2 ) { chosenIcon = Mr2Icon };

                        return (
                            <Marker
                                key={index}
                                icon={chosenIcon}
                                position={[marker.position.latitude, marker.position.longitude]}
                            >
                                <Popup>{marker.letter}</Popup>
                            </Marker>
                        );
                    }
                )}
            </LeafletMap>
        </Box>
    );
};
