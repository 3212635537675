import { generatePath } from "react-router";

export enum AppRoutesPath {
    root = "/",
    radar = "/radar/:radarid",
    radarLive = "/radar/:radarid/live",
    radarHistoric = "/radar/:radarid/historic",
    radarAnalytic = "/radar/:radarid/analytic",
    radarSystem = "/radar/:radarid/systemstatus",
    radarShutdown = "/radar/:radarid/shutdown",
    radarReporting = "/radar/:radarid/reporting",
    aeroecologycam = "/aeroecologycam/:ac1id",
    aeroecologycamLive = "/aeroecologycam/:ac1id/live",
    aeroecologycamSystem = "/aeroecologycam/:ac1id/system",
    mr2 = "/mr2/:mr2id",
    mr2Live = "/mr2/:mr2id/live",
    mr2System = "/mr2/:mr2id/system",
    administration = "/administration",
    administrationManageUsers = "/administration/manageusers",
    administrationAddDatabase = "/administration/adddatabase",
    administrationRadarDashboard = "/administration/radardashboard",
    login = "/login",
    loginWithParameters = "/login/:username/:password",
    logout = "/logout"
}

type RouteParameters = {
    username: string | null,
    password: string | null,
    radarid: string | null,
    ac1id: string | null,
    mr2id: string | null,
}

export const emptyRouteParameters: RouteParameters = {
    username: null,
    password: null,
    radarid: null,
    ac1id: null,
    mr2id: null
}

export const to = (route: AppRoutesPath, params?: RouteParameters): string => {
    return generatePath(route, params);
};
