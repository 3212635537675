import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    Grid,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
    Button,
    Tooltip,
    CircularProgress
} from "@material-ui/core";
import { IconButton, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { AppContext } from "../../store/context";
import { fetchPost } from "../../utils/fetch";
import { Trans, useTranslation } from "react-i18next";
import { UITypes } from "../../store/reducer";
import { CreateUser } from "./CreateUser";
import { Database, DatabaseList } from "./DatabaseList";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import StorageIcon from "@mui/icons-material/Storage";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    list: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        position: "relative",
        overflow: "auto",
        maxHeight: 1000
    },
    addUser: {
        marginRight: 22
    },
    item: {
        padding: 0
    }
}));

export interface User {
    id: number;
    username: string;
    email: string;
    profile_picture: string;
    role: string;
}

export const UserList = (): React.ReactElement => {
    const classes = useStyles();
    const [selectedUserId, setselectedUserId] = React.useState(0);
    const [users, setusers] = useState<User[] | undefined>([]);

    const [isAddUserDialogOpen, setisAddUserDialogOpen] = React.useState(false);

    const [isDatabasesLoading, setisDatabasesLoading] = useState<boolean>(false);
    const [databases, setdatabases] = useState<Database[] | undefined>(undefined);
    const [databasesUserSpecific, setdatabasesUserSpecific] = useState<Database[] | undefined>(undefined);

    const [error, setError] = useState<string>("");
    const [success, setSuccess] = useState<string>("");

    const { state } = useContext(AppContext);
    const { dispatch } = useContext(AppContext);
    const { t } = useTranslation();

    const requestUsers = useCallback((): void => {
        if (state.user) {
            fetchPost("get_users.php", {
                params: {
                    token: state.user.token
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setusers(data.users);
                });
        }
    }, [state.user, setusers]);

    useEffect(() => {
        requestUsers();
    }, [requestUsers]);

    const handleAddUserDialogOpen = (): void => {
        setisAddUserDialogOpen(true);
    };

    const handleAddUserDialogClose = (): void => {
        setisAddUserDialogOpen(false);
        requestUsers();
    };

    const handleListItemClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userId: number) => {
            setselectedUserId(userId);
        },
        [setselectedUserId]
    );

    const onClickConfirmDeleteUser = useCallback(
        (userId: number): void => {
            dispatch({
                type: UITypes.HideDialog,
                payload: {}
            });

            if (state.user) {
                fetchPost("delete_user.php", {
                    params: {
                        token: state.user.token,
                        userId: selectedUserId.toString()
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            if (data.success) {
                                requestUsers();
                                setSuccess(t("administration.deleteUserSuccess"));
                                return;
                            }
                        }
                        setError(data.error || t("error.couldNotDeleteUser"));
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        },
        [dispatch, selectedUserId, requestUsers, state.user, t]
    );

    const handleDeleteListItemClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userId: number, username: string) => {
            setselectedUserId(userId);
            dispatch({
                type: UITypes.ShowDialog,
                payload: {
                    title: t("administration.deleteUser"),
                    message: t("administration.confirmDeleteUserMessage", { user: username }),
                    buttons: [
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(event): void => {
                                onClickConfirmDeleteUser(userId);
                            }}
                        >
                            <Trans>label.yes</Trans>
                        </Button>
                    ]
                }
            });
        },
        [setselectedUserId, dispatch, onClickConfirmDeleteUser, t]
    );

    const onChangeDatabasePermissionsClicked = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, userId: number) => {
            if (state.user && userId !== 0) {
                setisDatabasesLoading(true);
                fetchPost("get_databases_for_user.php", {
                    params: {
                        token: state.user.token,
                        userId: userId.toString()
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            const databasesAll : Database[] | undefined = data.databasesAll;
                            const databasesUser : Database[] | undefined = data.databasesUser;

                            setdatabasesUserSpecific(databasesUser);
                            setdatabases(databasesAll);
                        } else {
                            setError(data.error || t("error.couldNotLoadDatabases"));
                        }
                        setisDatabasesLoading(false);
                    })
                    .catch((error) => {
                        setError(error.message);
                        setisDatabasesLoading(false);
                    });
            }
        },
        [state.user, t]
    );

    const onChangeDatabasePermissionsConfirmed = useCallback(() => {
        setdatabasesUserSpecific(undefined);
        setdatabases(undefined);
    }, []);

    if (!users) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <>
            <Grid container>
                <Grid container justifyContent="flex-end" className={classes.addUser}>
                    <IconButton color="primary" onClick={handleAddUserDialogOpen}>
                        <Tooltip title={t("administration.createUser") as string} placement="top">
                            <PersonAddIcon />
                        </Tooltip>
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <List component="nav" aria-label="users" className={classes.list}>
                        {users.map((user: User) => {

                            return (
                                <Grid container direction="column" key={user.id}>
                                    <Grid item xs={12}>
                                        <ListItem
                                            button
                                            selected={selectedUserId === user.id}
                                            onClick={(event): void => handleListItemClick(event, user.id)}
                                            key={user.id}
                                            classes={{ root: classes.item }}
                                        >
                                            <ListItemIcon>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={user.username + " (" + user.role + ")"}
                                                secondary={user.email}
                                            />
                                            <ListItemIcon
                                                onClick={(event): void =>
                                                    handleDeleteListItemClick(event, user.id, user.username)
                                                }
                                            >
                                                <Tooltip
                                                    title={t("administration.deleteUser") as string}
                                                    placement="top"
                                                >
                                                    <DeleteIcon />
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemIcon
                                                onClick={(event): void =>
                                                    onChangeDatabasePermissionsClicked(event, user.id)
                                                }
                                            >
                                                <Tooltip
                                                    title={t("administration.changeDatabasePermissions") as string}
                                                    placement="top"
                                                >
                                                    <StorageIcon />
                                                </Tooltip>
                                            </ListItemIcon>
                                        </ListItem>
                                    </Grid>
                                    {selectedUserId === user.id && !isDatabasesLoading && databases && users && (
                                        <Grid item xs={12}>
                                            <DatabaseList
                                                databases={databases}
                                                databasesUser={databasesUserSpecific}
                                                user={users.find((user) => user.id === selectedUserId)}
                                                onChangeDatabasePermissionsConfirmed={
                                                    onChangeDatabasePermissionsConfirmed
                                                }
                                            ></DatabaseList>
                                        </Grid>
                                    )}
                                </Grid>
                            );
                        })}
                    </List>
                </Grid>
                {error && (
                    <Grid item>
                        <Typography variant="body1" color="error">
                            {error}
                        </Typography>
                    </Grid>
                )}
                {success && (
                    <Grid item>
                        <Typography variant="body1" color="primary">
                            {success}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Dialog open={isAddUserDialogOpen} onClose={handleAddUserDialogClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <CreateUser />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddUserDialogClose} color="primary">
                        <Trans>label.close</Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
