import React, { useEffect, useContext, useState } from "react";
import { Outlet, useParams } from "react-router";
import { emptyRouteParameters, AppRoutesPath, to } from "../utils/routes";
import { PageSettings } from "./Page";
import { Grid } from "@material-ui/core";
import { Sidebar } from "../components/sidebar/Sidebar";
import { AppContext, UserRole } from "../store/context";
import { fetchPost } from "../utils/fetch";
import { useTranslation } from "react-i18next";
import { Moment } from "moment-timezone";

export interface Mr2Event {
    id: string;
    active: boolean;
    timestamp: Moment;
    stayActive: boolean;
    handledByUser: boolean;
    name: string;
    eventDescription: string;
    severity: number;
}

export interface Mr2 {
    id: number;
    name: string;
    active: boolean;
    getPdpViaFtp: boolean;
    comment: string;
    type: string;
    siteId: string;
    siteName: string;
    description: string;
    siteLongitude: number;
    siteLatitude: number;
    lastContact: string;
    displayName: string;
    lastDataTimestamp: string;
    events: Mr2Event[];
}

interface ProductMr2Props {
    onEnterPage(settings: PageSettings): void;
}

export const ProductMr2 = (props: ProductMr2Props): React.ReactElement => {
    const { mr2id } = useParams<{ mr2id: string }>();
    const { t } = useTranslation();
    const { onEnterPage } = props;

    const { state } = useContext(AppContext);
    const [mr2, setMr2] = useState<Mr2 | undefined>(undefined);

    useEffect(() => {
        if (state.user) {
            fetchPost("get_all_databases.php", { params: { token: state.user.token } })
                .then((response) => response.json())
                .then((data) => {
                    if (data.databases.mr2 && data.databases.mr2.length !== 0) {
                        data.databases.mr2.forEach((mr2: Mr2, index: number) => {
                            if (mr2.id.toString() === mr2id) {
                                setMr2({ ...mr2 });

                                const tabs = [{ label: "Live", to: to(AppRoutesPath.mr2Live, { ...emptyRouteParameters, mr2id: mr2id }) }];
                                if (
                                    state.user?.role === UserRole.Analyst ||
                                    state.user?.role === UserRole.AnalystWithDataPermission ||
                                    state.user?.isAdmin
                                ) {
                                    tabs.push({
                                        label: t("system.title"),
                                        to: to(AppRoutesPath.mr2System, { ...emptyRouteParameters, mr2id: mr2id })
                                    });
                                }

                                onEnterPage({
                                    title: mr2.siteName,
                                    backRoute: AppRoutesPath.root,
                                    tabs: {
                                        tabs: tabs
                                    }
                                });
                            }
                        });
                    }
                });
        }
    }, [mr2id, setMr2, onEnterPage, state.user, t]);

    if (!mr2) {
        return <Grid container>Loading</Grid>;
    } else {
        return (
            <Grid container spacing={2}>
                <Outlet context={mr2}/>
                <Grid item xs={12} md={2}>
                    <Sidebar mr2={mr2} />
                </Grid>
            </Grid>
        );
    }
};
