import React, { useState, useEffect, useContext, useCallback } from "react";
import { Container, Checkbox, FormControlLabel, CardContent, Card, Grid, makeStyles, Theme } from "@material-ui/core";

import { fetchPost } from "../../utils/fetch";
import { AppContext } from "../../store/context";
import { Mr1 } from "../ProductMr1";
import { Mr1Dashboard, Mr1Internal } from "./Mr1Dashboard";
import { Ac1 } from "../ProductAc1";
import { Ac1Dashboard, Ac1Internal } from "./Ac1Dashboard";
import { Mr2 } from "../ProductMr2";
import { Mr2Dashboard, Mr2Internal } from "./Mr2Dashboard";
import { getStatusFromLastContactTime, getStatusFromLastDataUpdateTime } from "../../components/ui/StatusLight";
import moment, { Moment } from "moment-timezone";

export interface ProductEvent {
    id: string;
    active: boolean;
    timestamp: Moment;
    stayActive: boolean;
    handledByUser: boolean;
    name: string;
    eventDescription: string;
    severity: number;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    item: {
        marginBottom: 10
    }
}));

export const Dashboard = (): React.ReactElement => {
    const { state } = useContext(AppContext);
    const classes = useStyles();

    const [showOnlyActiveProducts, setshowOnlyActiveProducts] = useState<boolean>(true);

    const [listMr1, setListMr1] = useState<{ radars: Mr1Internal[]; loaded: boolean; error: string }>({
        radars: [],
        loaded: false,
        error: ""
    });
    const [listAc1, setListAc1] = useState<{ aeroecologycams: Ac1Internal[]; loaded: boolean; error: string }>({
        aeroecologycams: [],
        loaded: false,
        error: ""
    });
    const [listMr2, setListMr2] = useState<{ mr2s: Mr2Internal[]; loaded: boolean; error: string }>({
        mr2s: [],
        loaded: false,
        error: ""
    });

    const getAllDatabases = useCallback((): void => {
        if (state.user) {
            fetchPost("get_all_databases.php", { params: { token: state.user.token } })
                .then((response) => response.json())
                .then((data) => {
                    if (data.databases.mr1 && data.databases.mr1.length !== 0) {
                        const newRadarList: Mr1Internal[] = data.databases.mr1.map((radar: Mr1, index: number) => {
                            const lastContactMoment = moment.tz(radar.lastContact, "YYYY-MM-DD HH:mm:ss", "UTC");
                            const radarStatus = getStatusFromLastContactTime(lastContactMoment);
                            const radarStatusText =
                                "Last radar contact: " + lastContactMoment.format("HH:mm:ss DD.MM.YYYY");
                            const lastDataMoment = moment.tz(radar.lastDataTimestamp, "YYYY-MM-DD HH:mm:ss", "UTC");
                            const dataStatus = getStatusFromLastDataUpdateTime(lastDataMoment);
                            const dataStatusText =
                                "Last data recorded: " + lastDataMoment.format("HH:mm:ss DD.MM.YYYY");

                            return {
                                id: radar.id,
                                letter: String(index + 1),
                                nameDatabase: radar.name,
                                active: radar.active,
                                comment: radar.comment,
                                nameSite: radar.siteName,
                                codeSite: radar.siteCode,
                                position: { lang: +radar.siteLatitude, lat: +radar.siteLongitude },
                                displayName: radar.displayName,
                                status: {
                                    wea: +radar.numberOfWindmills,
                                    radar: radarStatus,
                                    radarText: radarStatusText,
                                    data: dataStatus,
                                    dataText: dataStatusText
                                },
                                events: radar.events
                            };
                        });
                        setListMr1({ loaded: true, error: "", radars: newRadarList });
                    } else {
                        setListMr1({ loaded: true, error: "", radars: [] });
                    }
                    if (data.databases.ac1 && data.databases.ac1.length !== 0) {
                        const newRadarList: Ac1Internal[] = data.databases.ac1.map((ac1: Ac1, index: number) => {
                            const lastContactMoment = moment.tz(ac1.lastContact, "YYYY-MM-DD HH:mm:ss", "UTC");

                            const ac1Status = getStatusFromLastContactTime(lastContactMoment);

                            const ac1StatusText =
                                "Last AC1 contact: " + lastContactMoment.format("HH:mm:ss DD.MM.YYYY");
                            const lastDataMoment = moment.tz(ac1.lastDataTimestamp, "YYYY-MM-DD HH:mm:ss", "UTC");
                            const dataStatus = getStatusFromLastDataUpdateTime(lastDataMoment);
                            const dataStatusText =
                                "Last data recorded: " + lastDataMoment.format("HH:mm:ss DD.MM.YYYY");

                            return {
                                id: ac1.id,
                                letter: String(index + 1),
                                nameDatabase: ac1.name,
                                active: ac1.active,
                                comment: ac1.comment,
                                nameSite: ac1.siteName,
                                position: { longitude: +ac1.siteLongitude, latitude: +ac1.siteLatitude },
                                displayName: ac1.displayName,
                                status: {
                                    ac1: ac1Status,
                                    ac1Text: ac1StatusText,
                                    data: dataStatus,
                                    dataText: dataStatusText
                                },
                                events: ac1.events
                            };
                        });
                        setListAc1({ loaded: true, error: "", aeroecologycams: newRadarList });
                    } else {
                        setListAc1({ loaded: true, error: "", aeroecologycams: [] });
                    }
                    if (data.databases.mr2 && data.databases.mr2.length !== 0) {
                        const newRadarList: Mr2Internal[] = data.databases.mr2.map((radar: Mr2, index: number) => {
                            const lastContactMoment = moment.tz(radar.lastContact, "YYYY-MM-DD HH:mm:ss", "UTC");
                            const radarStatus = getStatusFromLastContactTime(lastContactMoment);
                            const radarStatusText =
                                "Last radar contact: " + lastContactMoment.format("HH:mm:ss DD.MM.YYYY");
                            const lastDataMoment = moment.tz(radar.lastDataTimestamp, "YYYY-MM-DD HH:mm:ss", "UTC");
                            const dataStatus = getStatusFromLastDataUpdateTime(lastDataMoment);
                            const dataStatusText =
                                "Last data recorded: " + lastDataMoment.format("HH:mm:ss DD.MM.YYYY");

                            return {
                                id: radar.id,
                                letter: String(index + 1),
                                nameDatabase: radar.name,
                                active: radar.active,
                                comment: radar.comment,
                                nameSite: radar.siteName,
                                position: { lang: +radar.siteLatitude, lat: +radar.siteLongitude },
                                displayName: radar.displayName,
                                status: {
                                    radar: radarStatus,
                                    radarText: radarStatusText,
                                    data: dataStatus,
                                    dataText: dataStatusText
                                },
                                events: radar.events
                            };
                        });
                        setListMr2({ loaded: true, error: "", mr2s: newRadarList });
                    } else {
                        setListMr2({ loaded: true, error: "", mr2s: [] });
                    }
                })
                .catch((error) => {
                    setListMr1({ loaded: true, error: error.message, radars: [] });
                    setListAc1({ loaded: true, error: error.message, aeroecologycams: [] });
                });
        }
    }, [state.user]);

    useEffect(() => {
        getAllDatabases();

        const id = setInterval(() => {
            getAllDatabases();
        }, 60000);

        return (): void => clearInterval(id);
    }, [state, setListMr1, setListAc1, setListMr2, getAllDatabases]);

    const onHandleEvent = useCallback(
        (nameDatabase: string, event: ProductEvent): void => {
            if (state.user) {
                fetchPost("handle_event.php", {
                    params: { token: state.user.token, databaseName: nameDatabase, eventId: event.id }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            getAllDatabases();
                        }
                    })
                    .catch((error) => {
                        setListMr1({ loaded: true, error: error.message, radars: [] });
                        setListAc1({ loaded: true, error: error.message, aeroecologycams: [] });
                        setListMr2({ loaded: true, error: error.message, mr2s: [] });
                    });
            }
        },
        [state.user, getAllDatabases]
    );

    const handleshowOnlyActiveProductsChange = (): void => {
        setshowOnlyActiveProducts(!showOnlyActiveProducts);
    };

    const onSetDatabaseActiveClicked = useCallback(
        (databaseName: string, active: boolean): void => {
            if (state.user) {
                fetchPost("set_database_active.php", {
                    params: {
                        token: state.user.token,
                        databaseName: databaseName,
                        active: active ? "true" : "false"
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            if (data.success) {
                                getAllDatabases();
                                return;
                            }
                        }
                    })
                    .catch((error) => {
                        setListMr1({ loaded: true, error: error.message, radars: [] });
                        setListAc1({ loaded: true, error: error.message, aeroecologycams: [] });
                        setListMr2({ loaded: true, error: error.message, mr2s: [] });
                    });
            }
        },
        [state.user, getAllDatabases]
    );

    return (
        <Container maxWidth="xl">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={showOnlyActiveProducts}
                        onChange={handleshowOnlyActiveProductsChange}
                        color="primary"
                    />
                }
                label={"Show active only"}
            />
            <Grid container direction="column">
                <Grid item className={classes.item}>
                    <Card>
                        <CardContent>
                            <Mr1Dashboard
                                radars={listMr1.radars}
                                loaded={listMr1.loaded}
                                error={listMr1.error}
                                showOnlyActiveProducts={showOnlyActiveProducts}
                                onHandleEvent={onHandleEvent}
                                onSetDatabaseActiveClicked={onSetDatabaseActiveClicked}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item className={classes.item}>
                    <Card>
                        <CardContent>
                            <Ac1Dashboard
                                ac1s={listAc1.aeroecologycams}
                                loaded={listAc1.loaded}
                                error={listAc1.error}
                                showOnlyActiveProducts={showOnlyActiveProducts}
                                onHandleEvent={onHandleEvent}
                                onSetDatabaseActiveClicked={onSetDatabaseActiveClicked}
                            />
                        </CardContent>
                    </Card>
                </Grid><Grid item className={classes.item}>
                    <Card>
                        <CardContent>
                            <Mr2Dashboard
                                mr2s={listMr2.mr2s}
                                loaded={listMr2.loaded}
                                error={listMr2.error}
                                showOnlyActiveProducts={showOnlyActiveProducts}
                                onHandleEvent={onHandleEvent}
                                onSetDatabaseActiveClicked={onSetDatabaseActiveClicked}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};
