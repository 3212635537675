import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router";
import { AppRoutesPath, to } from "../utils/routes";
import { Grid } from "@material-ui/core";
import { AppContext } from "../store/context";
import { useTranslation } from "react-i18next";
import { PageSettings } from "./Page";

interface AdministrationProps {
    onEnterPage(settings: PageSettings): void;
}

export const Administration = (props: AdministrationProps): React.ReactElement => {
    const { t } = useTranslation();
    const { onEnterPage } = props;

    const { state } = useContext(AppContext);

    useEffect(() => {
        onEnterPage({ title: t("administration.title"), backRoute: null, tabs: null });
        if (state.user) {
            onEnterPage({
                title: t("administration.title"),
                backRoute: AppRoutesPath.root,
                tabs: {
                    tabs: [
                        { label: t("administration.radarDashboard"), to: to(AppRoutesPath.administrationRadarDashboard) },
                        { label: t("administration.manageUsers"), to: to(AppRoutesPath.administrationManageUsers) },
                        { label: t("administration.addDatabase"), to: to(AppRoutesPath.administrationAddDatabase) }
                    ]
                }
            });
        }
    }, [onEnterPage, state.user, t]);

    if (!state.user || !state.user.isAdmin ) {
        return <Grid container>No user</Grid>;
    } else {
        return (
            <Grid container spacing={2}>
                <Outlet />
            </Grid>
        );
    }
};
